import { Box } from '@mui/material';
import './App.css';
import Header from './components/header/Header';

function ErrorPage() {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Header />
      <Box>
        error
      </Box>
    </Box>
  );
}

export default ErrorPage;
