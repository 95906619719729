import { Container } from '@mui/material'
import React from 'react'

export interface PageContainerProps {
    children: React.ReactNode
}

const PageContainer = ({ children }: PageContainerProps) => {
    return (
        <Container sx={{ display: "flex", flexDirection: "column", alignItems: "center", py: { xs: "50px", sm: "50px" } }}>
            {children}
        </Container>
    )
}

export default PageContainer