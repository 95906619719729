import React from 'react'
import Header from '../components/header/Header'
import { Box, Button, Container, List, ListItem, Typography } from '@mui/material'
import Layout from '../components/layout/Layout'
import { Link } from 'react-router-dom'
import LimestoneImg from '../assets/Raw Material Trading/Limestone-.jpg'
import BoricImg from '../assets/Raw Material Trading/BORIC ACIDE.jpg'
import CitricImg from '../assets/Raw Material Trading/Citric-Acid.jpg'
import FiberglassImg from '../assets/Raw Material Trading/Fiberglass 01.jpg.png'
import GypsumImg from '../assets/Raw Material Trading/Gypsum Powder-.jpg'
import UreaImg from '../assets/Raw Material Trading/UREA01.jpg'
import sodiumImg from '../assets/Raw Material Trading/sodium_bicarbonate.jpg'
import PageContainer from '../components/layout/PageContainer'

const RawMaterials = () => {
    return (
        <Layout>
            <PageContainer>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "30px", padding: "5px 20px" }}>
                    <Box>
                        <Typography variant='h1' sx={{ fontSize: "36px", fontWeight: "bold" }}>
                            Raw Material Trading
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "50px", mt: "50px" }}>
                        <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                            Raw materials are materials or substances used in the primary production or manufacturing of goods. As the leading supplier of raw material  we work together with customers of the market world-wide.<br></br>
                            our company strives to produce the highest quality products, including a wide variety of , Chemical raw materials, textile, construction and.... specialties.<br></br>
                            Our raw materials can be classified in different ways, these types are:<br></br>
                            - Extraction of raw materials extracted from the earth, such as ore, stone, lime, sand,coal etc.<br></br>
                            - Plant raw materials are derived from trees or plants, including resins, wood, cotton, and latex.<br></br>
                            - Animal raw materials are extracted from animal body coverings such as fur, leather and wool.<br></br>
                            - Chemicals and chemical compounds include fine chemicals, specialty chemicals, organic chemicals, inorganic chemicals, petrochemicals and pharmaceuticals. Common uses of chemicals include plastics, synthetic fibers, synthetic rubber, paints, coating, fertilizers, pesticides, cosmetics, soaps, cleaning agents and medicines. Chemicals are also used in mining, manufacturing and agricultural processes.
                            <br></br>
                            Some of the basic ingredients are :
                        </Typography>
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: "50px" }}>
                            <Box
                                component="img"
                                alt="About image"
                                src={CitricImg}
                                sx={{ width: { xs: "100%", sm: "20%" }, borderRadius: "10px", mt: "20px" }}
                            />
                            <List sx={{ listStyleType: 'disc', width: { xs: "100%", sm: "80%" } }}>
                                <ListItem sx={{ display: 'list-item', fontSize: "24px" }}>Citric acid :</ListItem>
                                <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                                    Citric acid is a colorless weak organic acid it used in such industries as detergent manufacturing, electroplating, leather tanning, pharmaceutical industry ect.
                                </Typography>
                            </List>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: "50px" }}>
                            <Box
                                component="img"
                                alt="About image"
                                src={BoricImg}
                                sx={{ width: { xs: "100%", sm: "20%" }, borderRadius: "10px", mt: "20px" }}
                            />
                            <List sx={{ listStyleType: 'disc', width: { xs: "100%", sm: "80%" } }}>
                                <ListItem sx={{ display: 'list-item', fontSize: "24px" }}>Boric acid :</ListItem>
                                <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                                    Boric acid is usually as colorless crystals or a white powder, that dissolves in water.it is often used as an antiseptic, insecticide, pottery, enamels, cosmetics, cements, porcelain, leather, carpets, hats, soaps, artificial gems, and more.
                                </Typography>
                            </List>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: "50px" }}>
                            <Box
                                component="img"
                                alt="About image"
                                src={sodiumImg}
                                sx={{ width: { xs: "100%", sm: "20%" }, borderRadius: "10px", mt: "20px" }}
                            />
                            <List sx={{ listStyleType: 'disc', width: { xs: "100%", sm: "80%" } }}>
                                <ListItem sx={{ display: 'list-item', fontSize: "24px" }}>Sodium bicarbonate :</ListItem>
                                <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                                    Sodium bicarbonate, commonly known as baking soda or bicarbonate of soda, is a white, crystalline solid, but often appears as a fine powder.<br></br>
                                    Sodium bicarbonate is one of the best and least expensive cleaning and bleaching agents. also used as a non-toxic pesticide and fungicide.
                                </Typography>
                            </List>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: "50px" }}>
                            <Box
                                component="img"
                                alt="About image"
                                src={UreaImg}
                                sx={{ width: { xs: "100%", sm: "20%" }, borderRadius: "10px", mt: "20px" }}
                            />
                            <List sx={{ listStyleType: 'disc', width: { xs: "100%", sm: "80%" } }}>
                                <ListItem sx={{ display: 'list-item', fontSize: "24px" }}>Urea :</ListItem>
                                <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                                    Urea is a a colourless, crystalline substance<br></br>
                                    Urea has important uses as a fertilizer and feed supplement, as well as a starting material for the manufacture of plastics and drugs.
                                </Typography>
                            </List>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: "50px" }}>
                            <Box
                                component="img"
                                alt="About image"
                                src={LimestoneImg}
                                sx={{ width: { xs: "100%", sm: "20%" }, borderRadius: "10px", mt: "20px" }}
                            />
                            <List sx={{ listStyleType: 'disc', width: { xs: "100%", sm: "80%" } }}>
                                <ListItem sx={{ display: 'list-item', fontSize: "24px" }}>Limestone :</ListItem>
                                <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                                    Limestone is  used in various industries, which is used in steel manufacturing, paper production, water treatment, and plastic production. Lime also has major applications in the manufacture of glass and in agriculture.
                                </Typography>
                            </List>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: "50px" }}>
                            <Box
                                component="img"
                                alt="About image"
                                src={GypsumImg}
                                sx={{ width: { xs: "100%", sm: "20%" }, borderRadius: "10px", mt: "20px" }}
                            />
                            <List sx={{ listStyleType: 'disc', width: { xs: "100%", sm: "80%" } }}>
                                <ListItem sx={{ display: 'list-item', fontSize: "24px" }}>Gypsum Powder :</ListItem>
                                <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                                    Gypsum has a variety of different types of features and applications. The most common type of gypsum in nature is non-crystalline gypsum used as the raw material in gypsum manufacturing factories. In addition, gypsum has different uses in industries of building, medicine, mold, production of Aluminum Sulfate, cement, Porcelain and ceramics.
                                </Typography>
                            </List>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: "50px" }}>
                            <Box
                                component="img"
                                alt="About image"
                                src={FiberglassImg}
                                sx={{ width: { xs: "100%", sm: "20%" }, borderRadius: "10px", mt: "20px" }}
                            />
                            <List sx={{ listStyleType: 'disc', width: { xs: "100%", sm: "80%" } }}>
                                <ListItem sx={{ display: 'list-item', fontSize: "24px" }}>Fiberglass :</ListItem>
                                <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                                    Fiberglass is a combination of resin and glass fibers which creates a fiber reinforced plastic.<br></br>
                                    The best part of fiberglass is its ability to get molded into various complex shapes. Because fiberglass is a low cost and highly flexible material it is used in various products and industries.
                                </Typography>
                            </List>
                        </Box>
                    </Box>
                </Box>
            </PageContainer>
        </Layout>
    )
}

export default RawMaterials