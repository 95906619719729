import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ErrorPage from './ErrorPage';
import AboutUs from './pages/AboutUs';
import { ThemeProvider, createTheme } from '@mui/material';
import WhoWeAre from './pages/WhoWeAre';
import ContactUs from './pages/ContactUs';
import OurBusiness from './pages/OurBusiness';
import HeavyMachinery from './pages/HeavyMachinery';
import TextileTrading from './pages/TextileTrading';
import RawMaterials from './pages/RawMaterials';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/about-us",
    element: <AboutUs />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/who-we-are",
    element: <WhoWeAre />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/business",
    element: <OurBusiness />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/business/heavy-machinery",
    element: <HeavyMachinery />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/business/textile-trading",
    element: <TextileTrading />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/business/raw-materials",
    element: <RawMaterials />,
    errorElement: <ErrorPage />,
  },
]);

const THEME = createTheme({
  typography: {
    "fontFamily": "monst",
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
  }
});
root.render(
  <React.StrictMode>
    <ThemeProvider theme={THEME}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
