import { Box } from '@mui/material'
import React from 'react'
import Header from '../header/Header'
import Footer from '../footer/Footer'

export interface LayoutProps {
    children: React.ReactNode
}

const Layout = ({ children }: LayoutProps) => {
    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Header />
            {children}
            <Footer />
        </Box>
    )
}

export default Layout