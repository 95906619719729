import React from 'react'
import Header from '../components/header/Header'
import { Box, Button, Container, Typography } from '@mui/material'
import Layout from '../components/layout/Layout'
import { Link } from 'react-router-dom'
import WhoWeAreImg from '../assets/whoweare.jpg'
import PageContainer from '../components/layout/PageContainer'

const WhoWeAre = () => {
    return (
        <Layout>
            <PageContainer>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "30px" }}>
                    <Box
                        component="img"
                        alt="About image"
                        src={WhoWeAreImg}
                        sx={{ width: "100%", borderRadius: "50px" }}
                    />
                    {/* <Box>
                        <Typography variant='h1' sx={{ fontSize: "36px", fontWeight: "bold" }}>
                            Who we are
                        </Typography>
                    </Box> */}
                    <Box sx={{ textAlign: "center" }}>
                        <Typography variant='caption' sx={{ fontSize: "16px" }}>
                            Our staff are competent, experienced and fully motivated.<br></br>
                            Our nature as a growing business reinforces our values and makes our Group a legacy to be preserved for the long term. <br></br>
                            Today we are an established and strong company supported by a highly motivated workforce. Every day our staff strives to take on new tasks with commitment and creativity to provide the best possible service to our customers. In doing so, we always keep an eye on forward-looking technologies and innovations to meet our demand for advanced solutions .<br></br>
                            The quality of our products is a matter of heart and a matter of course for us. With passion and precision, we ensure that our products meet the highest standards and satisfy our customers completely.<br></br>
                            Our main goal is to inspire our customers and to offer them a reliable partnership on which they can count. Their satisfaction and trust are the engine of our motivation.
                        </Typography>
                    </Box>
                </Box>
            </PageContainer>
        </Layout>
    )
}

export default WhoWeAre