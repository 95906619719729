import React from 'react'
import Header from '../components/header/Header'
import { Box, Button, Container, List, ListItem, Typography } from '@mui/material'
import Layout from '../components/layout/Layout'
import PageContainer from '../components/layout/PageContainer'
import { Link } from 'react-router-dom'
import BearingImg from '../assets/Heavy Equipment & Machinery Spare Parts Trading/Bearing.jpeg'
import HeavymachineryImg from '../assets/Heavy Equipment & Machinery Spare Parts Trading/Heavy machinery spare parts trading.png'
import InjectionImg from '../assets/Heavy Equipment & Machinery Spare Parts Trading/Injection pump011.jpg'
import CrankshaftImg from '../assets/Heavy Equipment & Machinery Spare Parts Trading/Crankshaft.png'
import DynamoImg from '../assets/Heavy Equipment & Machinery Spare Parts Trading/dynamo.jpeg'
import FilterImg from '../assets/Heavy Equipment & Machinery Spare Parts Trading/filter.png'
import CylinderImg from '../assets/Heavy Equipment & Machinery Spare Parts Trading/head cylinder.png'
import HydraulicHammerImg from '../assets/Heavy Equipment & Machinery Spare Parts Trading/hydraulic hammers.png'
import PistonImg from '../assets/Heavy Equipment & Machinery Spare Parts Trading/piston.jpeg'
import PumpImg from '../assets/Heavy Equipment & Machinery Spare Parts Trading/pump.png'
import ThermostatImg from '../assets/Heavy Equipment & Machinery Spare Parts Trading/thermostat-01.jpg'

const HeavyMachinery = () => {
    return (
        <Layout>
            <PageContainer>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "30px", padding: "5px 20px" }}>
                    <Box>
                        <Typography variant='h1' sx={{ fontSize: "36px", fontWeight: "bold" }}>
                            Heavy Equipment & Machinery Spare Parts Trading
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "50px", mt: "50px" }}>
                        <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                            Today, we are recognized as one of the largest heavy equipment and machinery spare parts business(Spare parts for all kinds of pumps, Hydraulic, bearing, drive components, engine Components,etc ) in the world and have extensive experience in all our business. We offer complete sales of heavy equipment and machinery spare parts worldwide . As such, our customer base is broad and diverse in terms of size, application and environment. All our parts meet strict quality criteria for outstanding operational performance.
                        </Typography>
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: "50px" }}>
                            <Box
                                component="img"
                                alt="About image"
                                src={PumpImg}
                                sx={{ width: { xs: "100%", sm: "20%" }, borderRadius: "10px", mt: "20px" }}
                            />
                            <List sx={{ listStyleType: 'disc', width: { xs: "100%", sm: "80%" } }}>
                                <ListItem sx={{ display: 'list-item', fontSize: "24px" }}>pump:</ListItem>
                                <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                                    A pump is a device that moves fluids (liquids or gases), or sometimes slurries, by mechanical action, typically converted from electrical energy into hydraulic energy.<br></br>
                                </Typography>
                            </List>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: "50px" }}>
                            <Box
                                component="img"
                                alt="About image"
                                src={HydraulicHammerImg}
                                sx={{ width: { xs: "100%", sm: "20%" }, borderRadius: "10px", mt: "20px" }}
                            />
                            <List sx={{ listStyleType: 'disc', width: { xs: "100%", sm: "80%" } }}>
                                <ListItem sx={{ display: 'list-item', fontSize: "24px" }}>hydraulic hammers:</ListItem>
                                <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                                    Hydraulic breakers are famous for their high performance and they are used for various applications such as demolition, rock drilling and road construction. <br></br>
                                </Typography>
                            </List>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: "50px" }}>
                            <Box
                                component="img"
                                alt="About image"
                                src={BearingImg}
                                sx={{ width: { xs: "100%", sm: "20%" }, borderRadius: "10px", mt: "20px" }}
                            />
                            <List sx={{ listStyleType: 'disc', width: { xs: "100%", sm: "80%" } }}>
                                <ListItem sx={{ display: 'list-item', fontSize: "24px" }}>Bearing:</ListItem>
                                <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                                    This piece is considered a type of bearing or rotating gear or hub and is used in any device that rotates to prevent friction and wear of the rotating surface. bearings are used in different parts of the car where friction is possible.
                                </Typography>
                            </List>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: "50px" }}>
                            <Box
                                component="img"
                                alt="About image"
                                src={ThermostatImg}
                                sx={{ width: { xs: "100%", sm: "20%" }, borderRadius: "10px", mt: "20px" }}
                            />
                            <List sx={{ listStyleType: 'disc', width: { xs: "100%", sm: "80%" } }}>
                                <ListItem sx={{ display: 'list-item', fontSize: "24px" }}>Thermostat:</ListItem>
                                <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                                    The thermostat is one of the important components in the car cooling system, its main function is to regulate the circulation of engine cooling from the radiator to prevent overheating of the engine.
                                </Typography>
                            </List>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: "50px" }}>
                            <Box
                                component="img"
                                alt="About image"
                                src={FilterImg}
                                sx={{ width: { xs: "100%", sm: "20%" }, borderRadius: "10px", mt: "20px" }}
                            />
                            <List sx={{ listStyleType: 'disc', width: { xs: "100%", sm: "80%" } }}>
                                <ListItem sx={{ display: 'list-item', fontSize: "24px" }}>Engine Filters:</ListItem>
                                <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                                    We deliver a full line of aftermarket fuel, lube, coolant and air intake filters for diesel engines, hydraulic and bulk tank filtration – plus exhaust system components.
                                </Typography>
                            </List>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: "50px" }}>
                            <Box
                                component="img"
                                alt="About image"
                                src={CylinderImg}
                                sx={{ width: { xs: "100%", sm: "20%" }, borderRadius: "10px", mt: "20px" }}
                            />
                            <List sx={{ listStyleType: 'disc', width: { xs: "100%", sm: "80%" } }}>
                                <ListItem sx={{ display: 'list-item', fontSize: "24px" }}>Cylinder head:</ListItem>
                                <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                                    he cylinder head sits at the top of the engine. Its purpose is to seal the top of the cylinder to create the combustion chamber. The head also forms the housing for the valve gear and spark plugs. Inside the cylinder head are complex passageways for coolant and oil.
                                </Typography>
                            </List>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: "50px" }}>
                            <Box
                                component="img"
                                alt="About image"
                                src={InjectionImg}
                                sx={{ width: { xs: "100%", sm: "20%" }, borderRadius: "10px", mt: "20px" }}
                            />
                            <List sx={{ listStyleType: 'disc', width: { xs: "100%", sm: "80%" } }}>
                                <ListItem sx={{ display: 'list-item', fontSize: "24px" }}>Injection pump:</ListItem>
                                <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                                    An Injection Pump is the device that pumps fuel into the cylinders of a diesel engine. Traditionally, the injection pump was driven indirectly from the crankshaft by gears, chains or a toothed belt (often the timing belt) that also drives the camshaft.
                                </Typography>
                            </List>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: "50px" }}>
                            <Box
                                component="img"
                                alt="About image"
                                src={CrankshaftImg}
                                sx={{ width: { xs: "100%", sm: "20%" }, borderRadius: "10px", mt: "20px" }}
                            />
                            <List sx={{ listStyleType: 'disc', width: { xs: "100%", sm: "80%" } }}>
                                <ListItem sx={{ display: 'list-item', fontSize: "24px" }}>Crankshaft:</ListItem>
                                <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                                    The crankshaft is a rotating shaft containing one or more crankpins,that are driven by the pistons via the connecting rods.
                                </Typography>
                            </List>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: "50px" }}>
                            <Box
                                component="img"
                                alt="About image"
                                src={PistonImg}
                                sx={{ width: { xs: "100%", sm: "20%" }, borderRadius: "10px", mt: "20px" }}
                            />
                            <List sx={{ listStyleType: 'disc', width: { xs: "100%", sm: "80%" } }}>
                                <ListItem sx={{ display: 'list-item', fontSize: "24px" }}>Piston:</ListItem>
                                <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                                    he piston’s function is to act as a moveable plug in the cylinder, forming the bottom part of the combustion chamber.the piston is pushed back up the cylinder by the rotating crankshaft and the cycle repeats itself.
                                </Typography>
                            </List>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: "50px" }}>
                            <Box
                                component="img"
                                alt="About image"
                                src={DynamoImg}
                                sx={{ width: { xs: "100%", sm: "20%" }, borderRadius: "10px", mt: "20px" }}
                            />
                            <List sx={{ listStyleType: 'disc', width: { xs: "100%", sm: "80%" } }}>
                                <ListItem sx={{ display: 'list-item', fontSize: "24px" }}>Dynamo:</ListItem>
                                <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                                    The dynamo supplies power to the generator engine and converts energy from engine fuel into electrical energy to run and can simultaneously charge the battery.
                                </Typography>
                            </List>
                        </Box>
                    </Box>
                </Box>
            </PageContainer>
        </Layout>
    )
}

export default HeavyMachinery