import React from 'react'
import Header from '../components/header/Header'
import { Box, Button, Container, List, ListItem, Typography } from '@mui/material'
import Layout from '../components/layout/Layout'
import TextileImgWool from '../assets/Textile Trading/wool.jpg'
import TextileImgSilk from '../assets/Textile Trading/SILk.jpg'
import TextileImgCotton from '../assets/Textile Trading/cotton.jpg'
import TextileImgLinen from '../assets/Textile Trading/linen.jpg'
import TextileImgPolyester from '../assets/Textile Trading/polyester.jpg'
import TextileImgNylon from '../assets/Textile Trading/nylon.jpg'
import PageContainer from '../components/layout/PageContainer'

const TextileTrading = () => {
    return (
        <Layout>
            <PageContainer>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "30px", padding: "5px 20px" }}>
                    <Box>
                        <Typography variant='h1' sx={{ fontSize: "36px", fontWeight: "bold" }}>
                            Textile trading
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "50px", mt: "50px" }}>
                        <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                            The textile industry includes The classification is below:
                        </Typography>
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: "50px" }}>
                            <Box sx={{ width: { xs: "100%", sm: "30%" }, display: "flex", flexDirection: "column" }}>
                                <Box
                                    component="img"
                                    alt="About image"
                                    src={TextileImgWool}
                                    sx={{ width: "100%", borderRadius: "10px", mt: "20px" }}
                                />
                                <Box
                                    component="img"
                                    alt="About image"
                                    src={TextileImgSilk}
                                    sx={{ width: "100%", borderRadius: "10px", mt: "20px" }}
                                />
                            </Box>
                            <List sx={{ width: { xs: "100%", sm: "70%" }, listStyleType: 'disc' }}>
                                <ListItem sx={{ display: 'list-item', fontSize: "24px" }}>Natural fibers with animal roots:</ListItem>
                                <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                                    Animal fibers are natural fibers that consist largely of certain proteins. for Examples include silk, hair/fur, wool and feathers. The animal fibers used most commonly both in the manufacturing world as well as by the hand spinners are wool from domestic sheep and silk.<br></br>
                                </Typography>
                                <Typography variant='h2' sx={{ fontSize: "18px", fontWeight: "bold", my: "10px" }}>
                                    Wool :<br></br>
                                </Typography>
                                <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                                    Wool is the general term used for soft, crimped fibers obtained from the wool of domestic sheep, and is used specifically in textile applications. Wool may be separated from the hair essentially by the fact that it covers the outer surface of any fiber. The various grades of wool are numerous, very small and thin, and are only connected at the base.
                                    Fabrics made of wool are insulated because of the air stored between
                                    the fibers.
                                </Typography>
                                <Typography variant='h2' sx={{ fontSize: "18px", fontWeight: "bold", my: "10px" }}>
                                    Silk :<br></br>
                                </Typography>
                                <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                                    These fabrics are very light and shiny, have high elasticity and are highly resistant to tearing and wrinkling.<br></br>
                                    Due to having protein, they are not heat conductors and keep them in themselves.
                                </Typography>
                            </List>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: "50px" }}>
                            <Box sx={{ width: { xs: "100%", sm: "30%" }, display: "flex", flexDirection: "column" }}>
                                <Box
                                    component="img"
                                    alt="About image"
                                    src={TextileImgCotton}
                                    sx={{ width: "100%", borderRadius: "10px", mt: "20px" }}
                                />
                                <Box
                                    component="img"
                                    alt="About image"
                                    src={TextileImgLinen}
                                    sx={{ width: "100%", borderRadius: "10px", mt: "20px" }}
                                />
                            </Box>
                            <List sx={{ width: { xs: "100%", sm: "70%" }, listStyleType: 'disc' }}>
                                <ListItem sx={{ display: 'list-item', fontSize: "24px" }}>Natural fibers with plant roots:</ListItem>
                                <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                                    Natural fibers with plant rootsare fibers that are produced by geological processes, or from the bodies of plants The fibers collected from the seeds of various plants are known as seed fibers. The most relevant example is cotton. Linen textiles can be made from flax plant fiber, yarn, as well as woven and knitted.
                                </Typography>
                                <Typography variant='h2' sx={{ fontSize: "18px", fontWeight: "bold", my: "10px" }}>
                                    Cotton :<br></br>
                                </Typography>
                                <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                                    Cotton fibers are heat conductors and transfer body heat to the outside, so they are cool.They are easily stained and washed and They are resistant to abrasion.
                                </Typography>
                                <Typography variant='h2' sx={{ fontSize: "18px", fontWeight: "bold", my: "10px" }}>
                                    Linen :<br></br>
                                </Typography>
                                <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                                    Linen fabrics have a smooth and shiny surface and are cleaned faster than cotton.
                                    They absorb as much water as cotton fabrics, but they dry faster, which is why linen towels are better than cotton.
                                </Typography>
                            </List>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: "50px" }}>
                            <Box sx={{ width: { xs: "100%", sm: "30%" }, display: "flex", flexDirection: "column" }}>
                                <Box
                                    component="img"
                                    alt="About image"
                                    src={TextileImgPolyester}
                                    sx={{ width: "100%", borderRadius: "10px", mt: "20px" }}
                                />
                                <Box
                                    component="img"
                                    alt="About image"
                                    src={TextileImgNylon}
                                    sx={{ width: "100%", borderRadius: "10px", mt: "20px" }}
                                />
                            </Box>
                            <List sx={{ width: { xs: "100%", sm: "70%" }, listStyleType: 'disc' }}>
                                <ListItem sx={{ display: 'list-item', fontSize: "24px" }}>Artificial fibers:</ListItem>
                                <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                                    Synthetic fibres are man-made fibres produced from chemical substances and are used for making clothes and other useful things. These are made by the process of polymerization. Synthetic fibres can either be completely synthetic or semisynthetic. Fibres that are purely synthetic like nylons, polyesters, acrylics are made from chemicals whereas semisynthetic fibres such as rayons are produced with the utilization of natural polymers as raw material.
                                </Typography>
                                <Typography variant='h2' sx={{ fontSize: "18px", fontWeight: "bold", my: "10px" }}>
                                    Polyester :<br></br>
                                </Typography>
                                <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                                    Polyester is completely synthetic.<br></br>
                                    They have excellent resistance to wrinkling even when wet.
                                </Typography>
                                <Typography variant='h2' sx={{ fontSize: "18px", fontWeight: "bold", my: "10px" }}>
                                    Nylon :<br></br>
                                </Typography>
                                <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                                    Nylon is a completely synthetic fiber.<br></br>
                                    Nylon has good strength against wear and tear, that's why it has many uses, including nylon socks, due to its high elasticity, it does not become baggy in the knee and joint areas.
                                </Typography>
                            </List>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: "50px" }}>
                            <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                                Creativity, research, experimentation and an important spinning know-how allow us to create fabrics with special yarns. We develop particularly innovative blends with noble yarns such as silk, linen, wool and cashmere.<br></br>
                                State-of-the-art machinery and cutting-edge technologies allow us to achieve excellent coloring performance and significant savings in energy resources, paying great attention to the environmental impact.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </PageContainer>
        </Layout>
    )
}

export default TextileTrading