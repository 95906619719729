import React from 'react'
import Header from '../components/header/Header'
import { Box, Button, Container, Typography } from '@mui/material'
import Layout from '../components/layout/Layout'
import { Link } from 'react-router-dom'
import AboutImg from '../assets/about-us.png'
import PageContainer from '../components/layout/PageContainer'

const AboutUs = () => {
    return (
        <Layout>
            <PageContainer>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "30px" }}>
                    <Box
                        component="img"
                        alt="About image"
                        src={AboutImg}
                        sx={{ width: "100%", borderRadius: "50px" }}
                    />
                    <Box>
                        <Typography variant='h1' sx={{ fontSize: "36px", fontWeight: "bold" }}>
                            About Us
                        </Typography>
                    </Box>
                    <Box sx={{ textAlign: "center" }}>
                        <Typography variant='caption' sx={{ fontSize: "16px" }}>
                            Triolin Trade - FZCO is a trading company that was established in 2023, this company is one of the largest and best trading companies active in the field of textile trading, heavy machinery spare parts trading and Trade in raw materials . Our goal is to provide quality assurance in all fields, and our priority is customer satisfaction.<br></br>
                            We’re here to use our unique expertise, capabilities, breadth and perspectives to open up new kinds of opportunity for our customers.<br></br>
                            Our customers are not only large manufacturers, but also small trading companies. The team constantly analyzes the needs of customers and the market place, due to which the range of supplied products is regularly expanded and updated.<br></br>
                            Thanks to the successful performance of our team , the company has gained the status of a reliable supplier, customer’s respect and a stable market position.<br></br>
                            With a philosophy deeply rooted in extensive market knowledge and personal relationships, the objectives of all the business entities involved are always carefully evaluated in order to execute flawless and profitable transactions.<br></br>
                            Using the latest technology, we've created a seamless experience for all traders. We communicate with our clients in ways they can easily understand, and we're always transparent about our pricing and trading conditions.<br></br>
                        </Typography>
                    </Box>
                </Box>
            </PageContainer>
        </Layout>
    )
}

export default AboutUs